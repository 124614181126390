import * as React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './src/index.css'
import './src/tailwind.css'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfm6i0gAAAAAA8Uy96XOgmMk9EKqoZm-iVn13wj">
      {element}
    </GoogleReCaptchaProvider>
  )
}
